@import "src/tokens";

.wrapper {
  // border-bottom: 1px solid $dark-alpha-20;
  position: sticky;
  top: 0;
  z-index: $layer-l;
  @include screen(smallPhone) {
    background-color: $bg-primary;
  }
  @include screen(phone) {
    background-color: $bg-primary;
  }
  @include screen(tablet) {
    background-color: $bg-primary;
  }
  @include screen(tabletLandscape) {
    background-color: transparent;
  }
  @include screen(desktop) {
    background-color: transparent;
  }
  @include screen(desktopWide) {
    background-color: transparent;
  }
}

.light {
  // background-color: #ffffff;
}

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  min-height: 64px;
  padding-left: $space-ml;
  padding-right: $space-ml;
}

.center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
}

.icon {
  color: $brand-primary-200;
  display: block;
  width: 24px;
  height: 24px;
}

.user {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: $space-ml;
  padding-top: $space-xs;
  padding-bottom: $space-xs;
  border-radius: 6px;
  transition: all 0.4s ease-in-out;
}

.avatar {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: $radius-rounded;
  margin-right: $space-s;
}

.name {
  font-size: 14px;
  line-height: 24px;
  font-weight: $medium;
  color: $dark-hex-70;
  padding-right: $space-s;
}

.chevron {
  transform: rotate(-90deg);
  display: block;
  width: 12px;
  height: 12px;
  color: $dark-hex-70;
}

.subtitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: $space-ll;
}

.flag {
  width: 64px;
  margin-right: $space-sm;
}

.text {
  font-size: 16px;
  font-weight: $semibold;
  color: $dark-hex-80;
}
