@import "src/tokens";

.wrapper {
  background-color: $bg-primary;
  box-shadow: inset 0px -1px 0px 0px $dark-alpha-20;
  position: sticky;
  top: 0;
  z-index: $layer-l;
}

.brandWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  @include screen(smallPhone) {
    padding-left: $space-s;
  }
  @include screen(phone) {
    padding-left: $space-s;
  }
  @include screen(tablet) {
    padding-left: $space-s;
  }
  @include screen(tabletLandscape) {
    padding-left: $space-m;
  }
  @include screen(desktop) {
    padding-left: $space-m;
  }
  @include screen(desktopWide) {
    padding-left: $space-m;
  }
}

.brand {
  padding-right: $space-m;
  margin-right: $space-ll;
  // @include screen(smallPhone) {
  //   position: absolute;
  //   transform: translateX(-50%);
  //   left: 50%;
  // }
  // @include screen(phone) {
  //   position: absolute;
  //   transform: translateX(-50%);
  //   left: 50%;
  // }
  // @include screen(tablet) {
  //   position: absolute;
  //   transform: translateX(-50%);
  //   left: 50%;
  // }
  @include screen(smallPhone) {
    margin-right: 0;
  }
  @include screen(phone) {
    margin-right: 0;
  }
  @include screen(tablet) {
    margin-right: 0;
  }
  @include screen(tabletLandscape) {
    position: static;
    transform: unset;
    left: unset;
    margin-right: $space-ll;
  }
  @include screen(desktop) {
    position: static;
    transform: unset;
    left: unset;
    margin-right: $space-ll;
  }
  @include screen(desktopWide) {
    position: static;
    transform: unset;
    left: unset;
    margin-right: $space-ll;
  }
}

.accountWrapper {
  align-items: center;
  justify-content: flex-end;
  justify-self: flex-end;
  margin-left: auto;
  padding-right: $space-m;

  @include screen(smallPhone) {
    display: inline-flex;
  }
  @include screen(phone) {
    display: inline-flex;
  }
  @include screen(tablet) {
    display: inline-flex;
  }
  @include screen(tabletLandscape) {
    display: inline-flex;
  }
  @include screen(desktop) {
    display: inline-flex;
  }
  @include screen(desktopWide) {
    display: inline-flex;
  }
}

.light {
  // background-color: #ffffff;
}

.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  min-height: 64px;
}

.center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
}

.icon {
  color: $brand-primary-200;
  display: block;
  width: 24px;
  height: 24px;
}

.companyPhoto {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}

.icon {
  width: 32px;
  height: 32px;
  color: $dark-hex-70;
  margin: auto;
}

.divider {
  width: 1px;
  height: 24px;
  margin-right: $space-ml;
  background-color: $dark-alpha-30;
}

.name {
  font-size: 14px;
  line-height: 24px;
  font-weight: $medium;
  color: $dark-hex-70;
  padding-right: $space-s;
}

.subtitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: $space-ll;
}

.flag {
  width: 64px;
  margin-right: $space-sm;
}

.text {
  font-size: 16px;
  font-weight: $semibold;
  color: $dark-hex-80;
}

.badge {
  margin-top: -4px;
  margin-bottom: -4px;
}

.mobileArea {
  background-color: $bg-primary;
  padding-top: $space-xl;
  padding: $space-ml;
  box-shadow: inset 0px 1px 0px 0px $dark-alpha-20;

  @include screen(smallPhone) {
    display: block;
  }
  @include screen(phone) {
    display: block;
  }
  @include screen(tablet) {
    display: block;
  }
  @include screen(tabletLandscape) {
    display: none;
  }
  @include screen(desktop) {
    display: none;
  }
  @include screen(desktopWide) {
    display: none;
  }
}

.toggleSidebar {
  cursor: pointer;
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: $radius-field;
  transition: all 0.4s ease-out;

  &:hover {
    background-color: $dark-alpha-20;
    transition: all 0.28s ease-out;
  }

  @include screen(smallPhone) {
    display: block;
  }
  @include screen(phone) {
    display: block;
  }
  @include screen(tablet) {
    display: block;
  }
  @include screen(tabletLandscape) {
    display: none;
  }
  @include screen(desktop) {
    display: none;
  }
  @include screen(desktopWide) {
    display: none;
  }
}

.burger {
  width: 24px;
  height: 24px;
  color: $dark-hex-80;
}

.mobileMenuList {
  display: grid;
  row-gap: $space-s;
}

.mobileMenuItem {
  padding-top: $space-s;
  padding-bottom: $space-s;
  font-size: 16px;
  line-height: 20px;
  font-weight: $medium;
}

.menuDesktop {
  @include screen(smallPhone) {
    display: none;
  }
  @include screen(phone) {
    display: none;
  }
  @include screen(tablet) {
    display: none;
  }
  @include screen(tabletLandscape) {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
  }
  @include screen(desktop) {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
  }
  @include screen(desktopWide) {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.area {
  width: 100%;
}
